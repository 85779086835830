import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useLanguage } from "../contexts/LanguageContext";
import faqBanner from "../assets/1.jpg";

interface FAQItem {
  question?: string;
  answer?: string;
  question2?: string;
  answer2?: string;
  question3?: string;
  answer3?: string;
}

const faqData: { [key in "en" | "ko" | "ja"]: FAQItem[] } = {
  en: [
    {
      question: "What is included in the tour price?",
      answer:
        "All costs except airfare are included (meals, accommodation, activities, etc.). However, if it is a tour where you drive the vehicle yourself, additional costs such as toll fees may apply. We recommend bringing a locally usable card and sufficient cash.",
    },
    {
      question: "Is there any dress code for the tour?",
      answer:
        "Airfare is not included in the tour price. However, you can add flights through Momento Korea (subject to availability). Please contact us if you wish to add a flight.",
    },
    {
      question: "Is there an age limit?",
      answer:
        "Due to national car insurance requirements, there is usually an age limit (typically 26 years or older; please inquire per country). This applies only to the driver.",
    },
    {
      question: "How many people participate in the group?",
      answer:
        "Typically, based on a 2-person per team standard, an average of 4-6 teams participate, though this may vary with reservations.",
    },
    {
      question: "Can I join the tour alone?",
      answer:
        "Yes, you can. However, single charges for hotel and vehicle use may apply. Please contact us via the 'Contact Us' tab for details.",
    },
    {
      question: "Is there any free time during the tour?",
      answer:
        "Every tour includes free time. Please consult the local instructor for adjustments based on the situation.",
    },
    {
      question:
        "Can I join even if I’m not experienced with sports car driving?",
      answer:
        "Yes, safe driving is ensured under the supervision of a professional instructor, and all safety instructions are provided over the walkie-talkie during the drive.",
    },
    {
      question2: "How do I make a tour reservation?",
      answer2:
        "You can select a tour schedule on our website, then contact us by phone or messenger app for consultation.",
    },
    {
      question2: "Can I change the tour schedule?",
      answer2:
        "You can modify your travel plan until the final payment date. If changes are made within the cancellation period, additional fees or penalties may apply. All changes depend on the fare difference and availability.\n\nNote: Additional fees might be charged. Please refer to the 'Fee and Deposit Policy' for details.",
    },
    {
      question2: "Can I add personal arrangements before or after the tour?",
      answer2:
        "We can arrange schedules for hotels, activities, rentals, etc. Please inform our travel planning team about your preferred area and dates.",
    },
    {
      question2:
        "What if I have dietary restrictions or special food requirements?",
      answer2:
        "When booking the tour, please indicate any dietary requirements in the designated field. Also, discuss your needs with the instructor at the start of the tour. Momento Korea accommodates special dietary needs such as vegetarian, diabetic, or gluten-free diets.",
    },
    {
      question2: "What payment methods are available?",
      answer2: "Cash, bank transfer, and credit cards are all accepted.",
    },
    {
      question2: "Is a deposit required?",
      answer2:
        "A deposit of 5,000,000 KRW per person is required for vehicle and hotel confirmation. Please refer to the refund policy on our website.",
    },
    {
      question2: "Can I add more people to my group?",
      answer2:
        "The standard is 2 persons per vehicle (for models like the 911 or 718). However, if you need a vehicle for 4–5 people (such as a Cayenne, Macan, Panamera, or Taycan), it is possible, and private tours can be arranged for groups of three or more teams.",
    },
    {
      question2: "Can I request a specific brand or model of vehicle?",
      answer2:
        "Regular tours do not allow specific requests, but for private tours, you can request your preferred vehicle and route.",
    },
    {
      question2: "Do I need an international driving permit?",
      answer2:
        "Yes, you must hold a domestic or international driving permit, along with a deposit credit card (up to 5,000 euros) and a passport.",
    },
    {
      question2: "What does the insurance cover?",
      answer2:
        "In case of an accident during the tour, a deductible of up to 5,000 euros may apply.",
    },
    {
      question3: "What should I do upon arrival at the local airport?",
      answer3:
        "A designated instructor will be waiting for your airport pickup. Please contact them using the provided contact information.",
    },
    {
      question3:
        "The first day might be exhausting after a long flight—will the schedule be too demanding?",
      answer3:
        "The first day of the tour is usually relaxed to allow time for rest before the tour begins in earnest. Typically, the group and the instructor gather for dinner as an introduction.",
    },
    {
      question3: "Can I switch vehicles during the tour?",
      answer3:
        "Due to insurance regulations, the program may differ by country. For instance, tours in Germany and Switzerland have fixed vehicles, while in France you might alternate vehicles daily. Please choose the tour that suits your preference.",
    },
    {
      question3:
        "What procedures should I follow if an accident occurs or a vehicle is damaged?",
      answer3:
        "If an accident makes the vehicle inoperable, the remaining schedule will be conducted in the instructor’s vehicle.",
    },
  ],
  ko: [
    {
      question: "투어 금액에는 무엇이 포함되어 있나요?",
      answer:
        "- 항공권을 제외한 모든 금액이 포함되어있습니다.(식사, 숙박, 액티비티 등) 다만, 차량을 직접 운행하는 투어일 경우 톨비와 같은 기타 비용이 발생할 수 있으므로 현지에서 사용이 가능한 카드와 충분한 현금을 가져오시는 것을 추천드립니다.",
    },
    {
      question: "여행 시 유의해야 할 복장 규정이 있나요?",
      answer:
        "- 항공권은 투어 금액에 포함되어 있지 않습니다. 다만, 모멘토 코리아를 통해 투어에 항공편을 추가하실 수 있습니다.(예약 가능 여부에 따라 다름) 항공편 추가를 원하실 경우, 저희 쪽으로 문의해주시면 친절히 답변 도와 드리겠습니다.",
    },
    {
      question: "나이 제한이 있나요?",
      answer:
        "- 국가별 차량 보험 등의 이유로 나이에 제한을 두고 있습니다. (통상 만 26세 이상, 국가별 상담문의) 이는 운전자에게만 해당이 됩니다.",
    },
    {
      question: "그룹에 얼마나 많은 사람들이 참여하나요?",
      answer:
        "- 2인 1팀 기준, 4~6팀이 평균적으로 참여합니다. 이는 예약 상황에 따라 변경될 수 있음을 알려드립니다.",
    },
    {
      question: "혼자서도 갈 수 있나요?",
      answer:
        "- 네, 물론입니다. 호텔, 차량 1인 사용에 대한 싱글 차지 비용이 발생하며, 상단 '문의하기' 탭을 통해 문의해주시면 담당자가 자세히 답변해드립니다.",
    },
    {
      question: "자유시간이 있을까요?",
      answer:
        "- 모든 투어에는 자유 시간이 포함되어 있습니다. 이는 현지 인스트럭터에게 문의해주시면 현장 상황에 따라 조절하는 것을 도와드립니다.",
    },
    {
      question: "스포츠카 운전이 익숙하지 않아도 참여할 수 있나요?",
      answer:
        "- 숙련된 인스트럭터의 통제에 따라 안전한 드라이빙을 진행하며, 무전기로 주행 중 모든 주의사항을 안내해 드리고 있습니다.",
    },
    {
      question2: "투어 예약은 어떻게 진행되나요?",
      answer2:
        "- 홈페이지에서 투어 일정을 정하신 뒤, 전화 혹은 메신저 앱으로 상담 도와드리고 있습니다.",
    },
    {
      question2: "투어 일정을 변경하고 싶습니다. 가능한가요?",
      answer2:
        "- 최종 결제일까지는 여행 계획을 자유롭게 변경할 수 있습니다. 취소 기간 내에 있는 경우 추가 요금 및 패널티가 적용될 수 있습니다. 모든 변경 사항은 요금 및 이용 가능 여부의 차이에 따라 달라질 수 있습니다. \n\n참고: 추가 수수료가 부과될 수 있습니다. 자세한 내용은 '수수료 및 계약금 규정'을 참조하세요.",
    },
    {
      question2: "투어 전후로 개인 일정을 추가하고 싶습니다.",
      answer2:
        "- 호텔과 액티비티, 렌탈 등 필요한 부분들에 대한 일정을 예약해드릴 수 있습니다.\n\n계획중인 지역과 일정 등을 우리의 여행 설계 담당자에게 알려주세요!",
    },
    {
      question2: "알러지나 특별히 필요한 식단이 있습니다. 어떻게 해야하나요?",
      answer2:
        "- 투어 예약 시, Dietary requirement 란에 특이 사항을 기입해주시길 바랍니다. 또한, 투어 시작 시 담당 인스트럭터와 요구 사항에 대해 논의해 주시길 바랍니다. 모멘토 코리아는 모든 참여자에게 필요한 특별한 식단 요구 사항(채식주의자, 당뇨인, 글루텐 프리 식단 등)등을 배려합니다.",
    },
    {
      question2: "결제 방법은 어떤 것이 있나요?",
      answer2: "- 현금, 계좌이체, 카드 모두 가능합니다.",
    },
    {
      question2: "계약금이 필요한가요?",
      answer2:
        "- 차량, 호텔 확정을 위해 계약금은 1인 500만원이며, 환불 규정은 홈페이지 하단 참고 부탁드립니다.",
    },
    {
      question2: "일행을 추가할 수 있나요?",
      answer2:
        "- 2인 1차량이 원칙이나(911,718 차량 기준), 4~5인 1차량을 원하실 경우(카이엔, 마칸, 파나메라, 타이칸)으로 대체 가능하시며 3팀 이상부터는 프라이빗 단독 투어 진행이 가능합니다.",
    },
    {
      question2: "특정 브랜드와 차량을 지정해서 예약할 수 있나요?",
      answer2:
        "- 정규 투어는 불가능하나, 프라이빗 투어로 문의주시면 원하시는 차량, 코스 모두 가능합니다.",
    },
    {
      question2: "국제운전면허증이 필요한가요?",
      answer2:
        "- 국내운전면허, 국제운전면허, 보증금용 신용카드(5000유로), 여권은 필수 소지하셔야 합니다.",
    },
    {
      question2: "보험이 보장하는 항목이 무엇인가요?",
      answer2:
        "- 차량 운행 중 사고시, 자기부담금 최대 5000유로 비용이 있습니다.",
    },
    {
      question3: "현지 공항에 도착하면 무엇을 하면 되나요?",
      answer3:
        "- 현지에 있는 담당 인스트럭터가 공항 픽업을 위해 대기할 것 입니다. 사전에 안내 드린 연락망으로 소통해주시면 됩니다.",
    },
    {
      question3:
        "첫 날은 장시간 비행으로 굉장히 피곤할 것 같은데 일정이 힘든가요?",
      answer3:
        "- 투어 첫 날은 보통 여유로운 일정과 함께 본격적인 투어를 위해 휴식하는 것을 원칙으로 합니다. \n그룹참여자 & 인스트럭터가 다같이 저녁식사를 하며 첫 인사를 하는 자리 정도로 일정이 마무리됩니다.",
    },
    {
      question3: "투어 중 차량을 번갈아 가며 탑승이 가능한가요?",
      answer3:
        "- 보험 규정으로 인해 국가별로 프로그램이 상이합니다. \n\n예를 들어, 독일과 스위스에서 진행하는 투어는 차량이 고정이며, 프랑스에서는 차량을 하루씩 번갈아 타는식으로 진행이 됩니다. 따라서 취향에 맞는 투어를 선택해주시면 됩니다.",
    },
    {
      question3: "사고가 발생하거나 차량이 손상되면 어떤 절차를 따라야하나요?",
      answer3:
        "- 사고가 발생해서 차량 운행이 불가능한 경우, 남은 일정은 인스트럭터 차량에 동승하는 것이 원칙입니다.",
    },
  ],
  ja: [
    {
      question: "ツアー料金には何が含まれていますか？",
      answer:
        "航空券を除くすべての費用（食事、宿泊、アクティビティなど）が含まれています。ただし、車を自分で運転するツアーの場合、通行料金などの追加費用が発生する可能性があるため、現地で利用可能なカードと十分な現金をご用意いただくことをお勧めします。",
    },
    {
      question: "旅行時に注意すべき服装規定はありますか？",
      answer:
        "航空券はツアー料金に含まれていません。ただし、モメントコリアを通じてツアーに航空便を追加することが可能です（予約状況により異なります）。航空便の追加をご希望の場合は、お問い合わせいただければ丁寧にご案内いたします。",
    },
    {
      question: "年齢制限はありますか？",
      answer:
        "国ごとの車両保険などの理由により、通常26歳以上という年齢制限があります（国によって異なるため、詳細はお問い合わせください）。これは運転者にのみ適用されます。",
    },
    {
      question: "グループにはどのくらいの人数が参加しますか？",
      answer:
        "2名1組を基準として、平均して4〜6組が参加します。ただし、予約状況により変更される場合があります。",
    },
    {
      question: "一人でも参加できますか？",
      answer:
        "はい、もちろん参加可能です。ただし、ホテルや車両のシングルチャージが発生する場合がありますので、詳細はお問い合わせください。",
    },
    {
      question: "自由時間はありますか？",
      answer:
        "すべてのツアーには自由時間が含まれています。現地のインストラクターにご相談いただければ、状況に応じて調整いたします。",
    },
    {
      question: "スポーツカーの運転に慣れていなくても参加できますか？",
      answer:
        "熟練したインストラクターの指導の下、安全なドライビングが行われ、無線で走行中の全ての注意事項が案内されます。",
    },
    {
      question2: "ツアーの予約はどのように行いますか？",
      answer2:
        "ウェブサイトでツアースケジュールを決定した後、電話またはメッセンジャーアプリでご相談に応じております。",
    },
    {
      question2: "ツアーの日程を変更したいのですが、可能ですか？",
      answer2:
        "最終決済日までは旅行プランを自由に変更することが可能です。ただし、キャンセル期間内の場合、追加料金やペナルティが適用される場合があります。すべての変更は料金や利用可能性の違いにより変動します。\n\n参考：追加手数料が発生する可能性があります。詳細は『手数料および契約金規定』をご参照ください。",
    },
    {
      question2: "ツアーの前後に個人のスケジュールを追加したいです。",
      answer2:
        "ホテル、アクティビティ、レンタルなど必要な項目のスケジュールを予約することが可能です。計画中の地域と日程を、当社の旅行プランナーにお知らせください。",
    },
    {
      question2:
        "アレルギーや特別な食事の必要がある場合はどうすればよいですか？",
      answer2:
        "ツアー予約時に「Dietary requirement」欄に特記事項を記入してください。また、ツアー開始時に担当インストラクターとご要望をお話しください。モメントコリアは、参加者のために必要な特別な食事要求（ベジタリアン、糖尿病、グルテンフリーなど）に配慮しております。",
    },
    {
      question2: "支払い方法はどのようなものがありますか？",
      answer2: "現金、振込、カードすべてがご利用いただけます。",
    },
    {
      question2: "契約金は必要ですか？",
      answer2:
        "車両やホテルの確定のため、1人あたり500万ウォンの契約金が必要です。返金規定はウェブサイト下部をご参照ください。",
    },
    {
      question2: "同行者を追加することはできますか？",
      answer2:
        "基本的には2名1台ですが（911、718基準）、4〜5名1台をご希望の場合は、カイエン、マカン、パナメーラ、タイカンなどにより対応可能です。また、3チーム以上の場合はプライベート専用ツアーも可能です。",
    },
    {
      question2: "特定のブランドや車両を指定して予約することは可能ですか？",
      answer2:
        "通常のツアーでは難しいですが、プライベートツアーであればご希望の車両やコースも対応可能です。",
    },
    {
      question2: "国際運転免許証は必要ですか？",
      answer2:
        "国内運転免許証、国際運転免許証、保証金用のクレジットカード（5000ユーロ分）、パスポートが必須です。",
    },
    {
      question2: "保険で保障される項目は何ですか？",
      answer2:
        "ツアー中の事故の場合、自己負担金として最大5000ユーロがかかる場合があります。",
    },
    {
      question3: "現地の空港に到着したら何をすればいいですか？",
      answer3:
        "現地の担当インストラクターが空港でピックアップのために待機します。事前にご案内した連絡先にご連絡ください。",
    },
    {
      question3:
        "初日は長時間のフライトで非常に疲れると思いますが、スケジュールは厳しいですか？",
      answer3:
        "ツアー初日は通常、余裕のあるスケジュールでツアー開始前に十分な休息を取ることが基本です。グループ参加者とインストラクターが共に夕食をとり、初対面の挨拶をする形でスケジュールが組まれています。",
    },
    {
      question3: "ツアー中に車両を交代して利用することは可能ですか？",
      answer3:
        "保険規定により国ごとにプログラムが異なります。たとえば、ドイツとスイスで実施されるツアーは車両が固定されていますが、フランスでは車両を1日ごとに交代して利用する形式となります。お好みに合わせたツアーをお選びください。",
    },
    {
      question3:
        "事故が発生したり車両が損傷した場合、どのような手続きをすればよいですか？",
      answer3:
        "事故により車両の運行が不可能になった場合、残りのスケジュールは担当インストラクターの車両に同乗するのが原則です。",
    },
  ],
};

const faqtext = {
  en: {
    title: "Before Tour",
    title2: "Getting ready for Tour",
    title3: "During Tour",
  },
  ko: {
    title: "투어 전",
    title2: "투어 준비",
    title3: "투어 진행 중",
  },
  ja: {
    title: "ツアー前",
    title2: "ツアー準備",
    title3: " ツアー中",
  },
};

const FAQsec = styled.div`
  background-color: black;
`;

const BannerSection = styled.div`
  width: 100%;
  height: 250px;
  background: url(${faqBanner}) center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
`;

const TittleText = styled.h2`
  color: white;
  margin-left: 20%;
`;

const FAQContainer = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  margin-bottom: 0px;
  background: #1a1a1a;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  color: white;
  text-align: center;
`;

const FAQItems = styled.div`
  background: #252525;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`;

const QuestionButton = styled.button`
  width: 100%;
  padding: 15px;
  text-align: left;
  background: none;
  border: none;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;

  &:hover {
    background: #333;
  }
`;

const Icon = styled.span`
  font-size: 1.5rem;
  transition: transform 0.3s ease-in-out;
`;

const AnswerContainer = styled(motion.div)`
  text-align: left;
  padding: 15px;
  font-size: 1rem;
  line-height: 1.6;
  background: #333;
  border-top: 1px solid #444;
`;

const FAQ: React.FC = () => {
  const { language } = useLanguage();
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <FAQsec>
      <BannerSection>Frequently Asked Questions</BannerSection>
      <br />
      <TittleText>{faqtext[language].title}</TittleText>
      <FAQContainer>
        {faqData[language]
          .filter((item) => !!item.question) // question 값이 존재하는 항목만 필터링
          .map((item, index) => (
            <FAQItems key={index}>
              <QuestionButton onClick={() => toggleFAQ(index)}>
                {item.question}
                <Icon>{openIndex === index ? "−" : "+"}</Icon>
              </QuestionButton>
              <AnimatePresence>
                {openIndex === index && item.answer && (
                  <AnswerContainer
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {item.answer}
                  </AnswerContainer>
                )}
              </AnimatePresence>
            </FAQItems>
          ))}
      </FAQContainer>
      <br />
      <br />
      <TittleText>{faqtext[language].title2}</TittleText>
      <FAQContainer>
        {faqData[language]
          .filter((item) => "question2" in item && "answer2" in item)
          .map((item, index) => (
            <FAQItems key={index}>
              <QuestionButton onClick={() => toggleFAQ(index)}>
                {(item as { question2: string }).question2}
                <Icon>{openIndex === index ? "−" : "+"}</Icon>
              </QuestionButton>
              <AnimatePresence>
                {openIndex === index && (
                  <AnswerContainer
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {(item as { answer2: string }).answer2}
                  </AnswerContainer>
                )}
              </AnimatePresence>
            </FAQItems>
          ))}
      </FAQContainer>

      <br />
      <br />
      <TittleText>{faqtext[language].title3}</TittleText>
      <FAQContainer>
        {faqData[language]
          .filter((item) => "question3" in item && "answer3" in item)
          .map((item, index) => (
            <FAQItems key={index}>
              <QuestionButton onClick={() => toggleFAQ(index)}>
                {(item as { question3: string }).question3}
                <Icon>{openIndex === index ? "−" : "+"}</Icon>
              </QuestionButton>
              <AnimatePresence>
                {openIndex === index && (
                  <AnswerContainer
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {(item as { answer3: string }).answer3}
                  </AnswerContainer>
                )}
              </AnimatePresence>
            </FAQItems>
          ))}
      </FAQContainer>
    </FAQsec>
  );
};

export default FAQ;
