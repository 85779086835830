import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/LanguageContext";
import { breakpoints } from "../styles/constants";
import bg1 from "../assets/OURSTORY2.png";
import bg2 from "../assets/OURSTORY3.jpg";
import bg3 from "../assets/OURSTORY4.jpg";
import bg4 from "../assets/OURSTORY5.jpg";
import img1 from "../assets/11.jpg";
import img2 from "../assets/point2.jpeg";
import img3 from "../assets/13.jpg";
import bannerImg from "../assets/14.jpg";

import curator1 from "../assets/C11.png";
import curator2 from "../assets/C22.png";

// About content (About Us 내용)
type ContentType = {
  [key: string]: {
    title: string;
    description: string;
  };
};

const content: ContentType = {
  ko: {
    title: "Our Story",
    description: `여행은 단순한 이동이 아니라, 그 순간을 소중히 여기는 것입니다.

Momento와 함께 여행의 새로운 기준을 경험하세요.
우리는 완벽하게 큐레이션된 순간을 위해 최상의 서비스를 제공합니다.

이제, 당신만의 특별한 이야기를 만들어 보세요.`,
  },
  en: {
    title: "Our Story",
    description: `Travel is not just about reaching a destination — it's about cherishing the journey itself.


With Momento, the definition of travel is rewritten. 
We always strive for excellence so that every moment becomes a perfectly curated journey. 

Embark on this journey and create your own unique story.`,
  },
  ja: {
    title: "Our Story",
    description: `旅は目的地にたどり着くことではなく、その過程を楽しむことです。

Momentoとともに、新しい旅のスタイルを体験してください。
私たちは、完璧にデザインされた最高のサービスを提供します。

さあ、あなただけの特別な物語を始めましょう。`,
  },
};

const tourData = {
  en: [
    {
      title: "Luxurious Accommodations",
      description:
        "Immerse yourself in elegance with stunning views and \n world-class comforts. Every detail is designed for an \nindulgent, unforgettable stay.",
      image: img1,
    },
    {
      title: "Gastronomic Journey Awaits You.",
      description:
        "Indulge your senses and elevate your journey with \n outstanding dining moments designed to delight and inspire.",
      image: img2,
    },
    {
      title: "A Tailored Journey, Crafted for You.",
      description:
        "Enjoy the privacy and exclusivity you deserve, with \npersonalized experiences that seamlessly blend comfort, \nexcitement, and discovery.",
      image: img3,
    },
  ],
  ko: [
    {
      title: "최고급 럭셔리 숙박",
      description:
        "아름다운 전망과 세계적인 시설 속에서 우아한 휴식을 즐겨보세요. 모든 순간을 잊지 못할 경험으로 만들어드립니다.",
      image: img1,
    },
    {
      title: "당신을 위한 미식의 여정",
      description:
        "오감을 만족시키는 최고의 다이닝 경험으로 여행의 품격을 높이세요.",
      image: img2,
    },
    {
      title: "당신만을 위한 맞춤 여행",
      description:
        "프라이빗하고 세심하게 설계된 여정으로 편안함과 설렘을 동시에 누리세요.",
      image: img3,
    },
  ],
  ja: [
    {
      title: "ラグジュアリーな滞在",
      description:
        "美しい景色と世界トップクラスの設備で、 優雅なひとときをお楽しみください。",
      image: img1,
    },
    {
      title: "あなたのための美食の旅",
      description:
        "五感を満たす最高のダイニング体験で、 旅をより特別なものに。",
      image: img2,
    },
    {
      title: "あなたのための特別な旅",
      description:
        "プライベートで細部までこだわった旅で、 快適さとワクワク感を同時に味わいましょう。",
      image: img3,
    },
  ],
};

const curatorData = {
  en: [
    {
      name: "CHRIS KIM",
      country: "SOUTH KOREA",
      title: "CEO l SENIOR TOUR OPERATOR",
      description:
        "We don’t just offer travel packages— \n we strive to make each customer’s dream come true.",
      image: curator1,
    },
    {
      name: "KAY LEE",
      country: "SOUTH KOREA",
      title: "CEO l SENIOR TOUR OPERATOR",
      description:
        "There are endless places to explore in the world, \n but what truly matters is how you experience them.",
      image: curator2,
    },
  ],
  ko: [
    {
      name: "김창준",
      country: "SOUTH KOREA",
      title: "CEO | SENIOR TRAVEL EXPERIENCE MANAGER",
      description:
        "저희는 단순한 여행 상품이 아니라, 고객의 꿈을 현실로 만듭니다.",
      image: curator1,
    },
    {
      name: "이기석",
      country: "SOUTH KOREA",
      title: "CEO | SENIOR TRAVEL EXPERIENCE MANAGER",
      description:
        "세상에는 무한한 여행지가 있지만, \n 중요한 것은 ‘어떻게’ 경험하느냐입니다",
      image: curator2,
    },
  ],
  ja: [
    {
      name: "キム・チャンジュン",
      country: "SOUTH KOREA",
      title: "CEO | SENIOR TRAVEL EXPERIENCE MANAGER",
      description:
        "私たちは単なる旅行パッケージではなく、お客様の夢を叶えます。",
      image: curator1,
    },
    {
      name: "イ・ギソ",
      country: "SOUTH KOREA",
      title: "CEO | SENIOR TRAVEL EXPERIENCE MANAGER",
      description:
        "世界には無限の旅先がありますが、 大切なのは「どのように」体験するかです。",
      image: curator2,
    },
  ],
};

const quotes = {
  en: "“Rather than serving you through staff members, we, as a representatives, personally accompany you to create the finest moments of your journey”",
  ko: "“모멘토 코리아는 직원을 통해 고객님을 모시는 것이 아니라, 고객님께 최고의 순간을 선사하기 위해 대표자로서 직접 모시겠습니다.”",
  ja: "“私たちはスタッフを通じてではなく、お客様に最高の瞬間をお届けするため、代表者として直接ご案内いたします。”",
};

const sampleVideos = [
  "https://youtu.be/LcgFU8EtZVU",
  "https://www.youtube.com/embed/c5LeLdbK_-A",
  "https://www.youtube.com/embed/09VH73mPKcY",
];

// 유튜브 embed URL에서 영상 ID를 추출하여 썸네일 이미지 URL을 생성하는 함수
const getYoutubeThumbnail = (embedUrl: string): string => {
  const parts = embedUrl.split("/");
  const videoId = parts[parts.length - 1];
  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
};

const About: React.FC = () => {
  const { language } = useLanguage();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const backgroundImages = [bg1, bg2, bg3, bg4];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prev) =>
        prev === backgroundImages.length - 1 ? 0 : prev + 1
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [backgroundImages.length]);

  // Recent Tour modal video state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState("");

  const openVideoModal = (videoUrl: string) => {
    setCurrentVideo(videoUrl);
    setIsModalOpen(true);
  };

  const closeVideoModal = () => {
    setIsModalOpen(false);
    setCurrentVideo("");
  };

  return (
    <PageContainer>
      <BannerSection></BannerSection>

      <ContentWrapper bgImage={backgroundImages[currentImageIndex]}>
        <Title>{content[language].title}</Title>
        <Description>{content[language].description}</Description>
      </ContentWrapper>

      <TourContainer>
        {tourData[language].map((tour, index) => (
          <TourBlock key={index} reverse={index % 2 !== 0}>
            <TourImage bgImage={tour.image} />
            <TourText>
              <MobileImageBackground bgImage={tour.image} />
              <h2>{tour.title}</h2>
              <p>{tour.description}</p>
            </TourText>
          </TourBlock>
        ))}
      </TourContainer>

      <hr />
      <PageContainer>
        <SectionTitle>Meet the Curator</SectionTitle>
        <CuratorContainer>
          {curatorData[language].map((curator, index) => (
            <CuratorCard key={index}>
              <ProfileImage src={curator.image} alt={curator.name} />
              <InfoBox>
                <p>{curator.country}</p>
                <h3>{curator.name}</h3>
                <p>{curator.title}</p>
                <p>{curator.description}</p>
              </InfoBox>
            </CuratorCard>
          ))}
        </CuratorContainer>

        <QuoteSection>{quotes[language]}</QuoteSection>
      </PageContainer>
      <hr />
      <RecentTourSection>
        <SectionTitle>Recent Tour</SectionTitle>
        <VideoContainer>
          {sampleVideos.map((video, index) => (
            <VideoBlock key={index}>
              <VideoThumbnail>
                <ThumbnailImage
                  src={getYoutubeThumbnail(video)}
                  alt={`video-thumbnail-${index}`}
                />
                <PlayButton onClick={() => openVideoModal(video)}>▶</PlayButton>
              </VideoThumbnail>
            </VideoBlock>
          ))}
        </VideoContainer>
      </RecentTourSection>

      <hr />

      {isModalOpen && (
        <ModalOverlay onClick={closeVideoModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeVideoModal}>&times;</CloseButton>
            <iframe
              src={currentVideo}
              title="Recent Tour Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </ModalContent>
        </ModalOverlay>
      )}
    </PageContainer>
  );
};

export default About;

/* ------ styled-components ------ */

const ContentWrapper = styled.div<{ bgImage: string }>`
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  border-radius: 20px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${(props) => props.bgImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 1.5s ease;
    animation: fadeIn 1.5s ease-in-out;
    opacity: 0.5;
    z-index: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
    z-index: 1;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(1.1);
    }
    to {
      opacity: 0.5;
      transform: scale(1);
    }
  }
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
  z-index: 2;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 2.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  white-space: pre-line;
  color: #ccc;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  word-break: keep-all;
  word-wrap: break-word;
  position: relative;
  z-index: 2;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 1rem;
    line-height: 1.6;
  }
`;

const TourContainer = styled.div`
  max-width: 1200px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  gap: 0px;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;

const TourBlock = styled.div<{ reverse?: boolean }>`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  height: 300px;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    gap: 0;
  }
`;

const TourImage = styled.div<{ bgImage: string }>`
  flex: 1;
  background: url(${(props) => props.bgImage}) center/cover no-repeat;
  height: 80%;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileImageBackground = styled.div<{ bgImage: string }>`
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${(props) => props.bgImage}) center/cover no-repeat;
    filter: brightness(0.5);
    z-index: 0;
  }
`;

const TourText = styled.div`
  flex: 1;
  background: #222;
  border: 2px solid white;
  color: white;
  padding: 0px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.1);
  text-align: center;
  margin: 0 auto;
  position: relative;

  @media (max-width: 768px) {
    width: 95%;
    min-height: 300px;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    position: relative;
    z-index: 2;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.6;
    position: relative;
    z-index: 2;
  }

  @media (max-width: 768px) {
    h2,
    p {
      color: white;
      text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.8);
    }
  }
`;

const RecentTourSection = styled.div`
  max-width: 1200px;
  margin: 50px auto;
  padding: 0 2rem;
`;

const VideoBlock = styled.div`
  flex: 1;
  position: relative;
`;

const VideoContainer = styled.div`
  display: flex;
  justify-content: center; /* 중앙 정렬 */
  gap: 20px;
  flex-wrap: wrap; /* 필요 시 줄바꿈 */

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const VideoThumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: black;
  border-radius: 12px;
  overflow: hidden;
  border: 2px solid #ffd700; /* 노란색 테두리 추가 */
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`;

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5rem;
  background: rgba(255, 215, 0, 0.8);
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 2;
  &:hover {
    background: rgba(255, 215, 0, 1);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const ModalContent = styled.div`
  position: relative;
  width: 80%;
  max-width: 800px;
  background: #000;
  padding: 1rem;
  border-radius: 12px;
  iframe {
    width: 100%;
    height: 450px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: -20px;
  right: -20px;
  background: #ffd700;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 2rem;
  cursor: pointer;
`;

const SectionTitle = styled.h1`
  font-size: 3.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
  z-index: 2;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 2.5rem;
  }
`;

const PageContainer = styled.div`
  background: black;
  padding-bottom: 50px;
  min-height: 100vh;
`;

const BannerSection = styled.div`
  width: 100%;
  height: 100px;
  /* background: url(${bannerImg}) center/cover no-repeat; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
`;

const CuratorContainer = styled.div`
  max-width: 1200px;
  margin: 50px auto;
  display: flex;
  justify-content: center;
  gap: 80px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
`;

const CuratorCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.2);
  margin-bottom: -50px;
  z-index: 1;

  @media (max-width: 768px) {
    width: 180px;
    height: 180px;
    margin-bottom: -40px;
  }
`;

const InfoBox = styled.div`
  width: 280px;
  padding: 50px 20px 30px;
  background: #004225;
  border-radius: 20px;
  color: white;
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 0;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    width: 90%;
    padding: 40px 15px 25px;
  }
`;

const QuoteSection = styled.div`
  text-align: center;
  margin-top: 60px;
  font-style: italic;
  font-size: 1.3rem;
  color: white;
  padding: 0 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Playfair Display", serif;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;
