import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/LanguageContext";
import { breakpoints } from "../styles/constants";
import { useNavigate } from "react-router-dom";
import Logo1 from "../assets/logo.png";
import KO from "../assets/flags/KO.png";
import US from "../assets/flags/US.png";
import JP from "../assets/flags/JP.png";

type LanguageType = "en" | "ko" | "ja";

// 언어코드 -> 국기 매핑
const languageFlag: Record<LanguageType, string> = {
  en: US,
  ko: KO,
  ja: JP,
};

const translations = {
  en: {
    home: "Home",
    about: "About",
    services: "Services",
    contact: "Contact",
    calendar: "Calendar",
    tours: "Tours",
    faq: "FAQ",
  },
  ko: {
    home: "홈",
    about: "소개",
    services: "서비스",
    contact: "문의하기",
    calendar: "캘린더",
    tours: "투어",
    faq: "FAQ",
  },
  ja: {
    home: "ホーム",
    about: "紹介",
    services: "サービス",
    contact: "お問い合わせ",
    calendar: "カレンダー",
    tours: "ツアー",
    faq: "FAQ",
  },
};

const Header = () => {
  const navigate = useNavigate();
  const { language, setLanguage } = useLanguage();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // 데스크톱 드롭다운 열림 여부
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // body 스크롤 제어 (모바일 메뉴)
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isMenuOpen]);

  const t = translations[language];

  const handleNavClick = (path: string) => {
    navigate(path);
    setIsMenuOpen(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // 드롭다운에서 언어 선택 (데스크톱 전용)
  const handleLanguageSelect = (lang: LanguageType) => {
    setLanguage(lang);
    setDropdownOpen(false);
    setIsMenuOpen(false);
  };

  // 모바일용 언어 버튼 (가로 배치) - 눌렀을 때 언어 바로 변경
  const handleMobileLangSelect = (lang: LanguageType) => {
    setLanguage(lang);
    setIsMenuOpen(false);
  };

  return (
    <HeaderContainer isScrolled={isScrolled}>
      <HeaderContent>
        <Logo
          isScrolled={isScrolled}
          onClick={() => handleNavClick("/")}
          style={{ cursor: "pointer" }}
        >
          <LogoImg src={Logo1} alt="Logo" />
          {/* MOMENTO */}
        </Logo>

        <NavMenu isOpen={isMenuOpen} isScrolled={isScrolled}>
          <NavItem isScrolled={isScrolled} onClick={() => handleNavClick("/")}>
            {t.home}
          </NavItem>
          <NavItem
            isScrolled={isScrolled}
            onClick={() => handleNavClick("/about")}
          >
            {t.about}
          </NavItem>
          <NavItem
            isScrolled={isScrolled}
            onClick={() => handleNavClick("/tours")}
          >
            {t.tours}
          </NavItem>
          {/* <NavItem
            isScrolled={isScrolled}
            onClick={() => handleNavClick("/calendar")}
          >
            {t.calendar}
          </NavItem> */}
          <NavItem
            isScrolled={isScrolled}
            onClick={() => handleNavClick("/contact")}
          >
            {t.contact}
          </NavItem>

          <NavItem
            isScrolled={isScrolled}
            onClick={() => handleNavClick("/faq")}
          >
            {t.faq}
          </NavItem>

          {/* ================= 데스크톱 언어 섹션 ================= */}
          <DesktopLangSection>
            <LanguageDropdownWrapper>
              <StyledLanguageButton
                isScrolled={isScrolled}
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                {/* 현재 언어의 국기를 표시 */}
                {/* {languageFlag[language]} */}
                <FlagImage src={languageFlag[language]} alt="flag" />
              </StyledLanguageButton>

              {/* 드롭다운 */}
              {dropdownOpen && (
                <DropdownMenu>
                  {/* 드롭다운 항목도 국기로 표시 */}
                  {(["en", "ko", "ja"] as LanguageType[]).map((lang) => (
                    <DropdownItem
                      key={lang}
                      onClick={() => handleLanguageSelect(lang)}
                    >
                      {/* {languageFlag[lang]} */}
                      <FlagImage src={languageFlag[lang]} alt="flag" />
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              )}
            </LanguageDropdownWrapper>
          </DesktopLangSection>

          {/* ================= 모바일 언어 섹션 (가로 버튼 3개) ================= */}
          <MobileLangSection>
            {(["en", "ko", "ja"] as LanguageType[]).map((lang) => (
              <MobileLangButton
                key={lang}
                onClick={() => handleMobileLangSelect(lang)}
              >
                {/* {languageFlag[lang]} */}
                <FlagImage src={languageFlag[lang]} alt="flag" />
              </MobileLangButton>
            ))}
          </MobileLangSection>
        </NavMenu>

        {/* 햄버거 버튼 */}
        <MenuButton
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          isOpen={isMenuOpen}
        >
          <span />
          <span />
          <span />
        </MenuButton>
      </HeaderContent>

      {/* 배경 오버레이 */}
      {isMenuOpen && <Overlay onClick={() => setIsMenuOpen(false)} />}
    </HeaderContainer>
  );
};

export default Header;

/* ----------------------- Styled Components ----------------------- */

const HeaderContainer = styled.header<{ isScrolled: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: ${({ isScrolled }) =>
    isScrolled ? "rgba(0, 0, 0, 0.95)" : "transparent"};
  box-shadow: ${({ isScrolled }) =>
    isScrolled ? "0 2px 10px rgba(0, 0, 0, 0.1)" : "none"};
  z-index: 1000;
  transition: all 0.3s ease;
`;

const HeaderContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div<{ isScrolled: boolean }>`
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px;
  font-family: "Gruppo", sans-serif;
`;

const LogoImg = styled.img`
  width: 170px;
`;

const NavMenu = styled.nav<{ isOpen: boolean; isScrolled: boolean }>`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: ${breakpoints.mobile}) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    backdrop-filter: blur(10px);
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    transition: all 0.7s ease;
    visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
    z-index: 999;
    pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
    overflow: hidden;
  }
`;

const NavItem = styled.a<{ isScrolled: boolean }>`
  text-decoration: none;
  color: ${({ isScrolled }) => (isScrolled ? "#fff" : "#fff")};
  font-weight: 500;
  transition: color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: #ffd700;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 1rem;
    width: 100%;
    text-align: center;
  }
`;

const MenuButton = styled.button<{ isOpen: boolean }>`
  display: none;
  flex-direction: column;
  gap: 6px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  z-index: 2000;
  position: relative;

  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
  }

  span {
    width: 24px;
    height: 2px;
    background: #fff;
    transition: all 0.3s ease;
    position: relative;

    &:first-child {
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(45deg) translate(6px, 5px)" : "rotate(0)"};
    }

    &:nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
    }

    &:last-child {
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(-45deg) translate(6px, -5px)" : "rotate(0)"};
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.574);
  backdrop-filter: blur(10px);
  z-index: 900;
  display: none;

  @media (max-width: ${breakpoints.mobile}) {
    display: block;
    pointer-events: auto;
  }
`;

/* 
  =========================
  데스크톱 전용 (기존 드롭다운)
  =========================
*/
const DesktopLangSection = styled.div`
  /* 모바일에서는 숨김 */
  @media (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

const LanguageDropdownWrapper = styled.div`
  position: relative;
`;

const StyledLanguageButton = styled.button<{ isScrolled: boolean }>`
  padding: 0.5rem 1rem;
  border: 2px solid #ffd700;
  background: transparent;
  color: ${({ isScrolled }) => (isScrolled ? "#fff" : "#fff")};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 50px;
  text-transform: uppercase;

  &:hover {
    background: #ffd700;
    color: black;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 120%;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  border: 2px solid #ffd700;
  border-radius: 4px;
  margin-top: 0.5rem;
  overflow: hidden;
  z-index: 9999;
  display: flex;
  flex-direction: column;
`;

const DropdownItem = styled.div`
  padding: 0.8rem 1rem;
  color: #fff;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 215, 0, 0.2);
  text-align: center;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: #ffd700;
    color: #000;
  }
`;
const FlagImage = styled.img`
  width: 18px;
  height: auto;
`;

/* 
  =========================
  모바일 전용 (가로 배치)
  =========================
*/
const MobileLangSection = styled.div`
  display: none; /* 데스크톱에서는 숨김 */

  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
    gap: 1rem;
    margin-top: 1rem; /* 햄버거 메뉴 안에서 약간 간격 */
  }
`;

/* 각 언어 버튼 (모바일 전용) */
const MobileLangButton = styled.button`
  padding: 0.6rem 1.2rem;
  background: #ffd700;
  border: none;
  color: #000;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  min-width: 60px;
  text-transform: uppercase;

  &:hover {
    background: #ffa500;
  }
`;
