import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLanguage } from "../contexts/LanguageContext";
import { useLocation, useNavigate } from "react-router-dom";
import InstagramIco from "../assets/Instagram.png";
import WhatsappIco from "../assets/WhatsApp.png";
import ChatIco from "../assets/chat.png";

// webpack의 require.context를 사용해 ./data 폴더 내 모든 JSON 파일 불러오기
const tourContext = (require as any).context("./data", false, /\.json$/);

// 타입 정의
interface TourInfo {
  title: string;
  subtitle: string;
  flagImage: string;
  flagImage2: string;
  flagImage3: string;
  subtitle2: string;
  period: string;
  highlights: string[];
  includes: string[];
  description: string;
  shortDesc?: string;
  pdfLink: string;
}

interface TourDetailData {
  status: "On" | "Off";
  image: string;
  price: string;
  country: "korea" | "europe" | "japan";
  slug: string;
  start: Date;
  end: Date;
  ko: TourInfo;
  en: TourInfo;
  ja: TourInfo;
}

// 모든 JSON 파일을 동적으로 불러와 tourDetails 객체 구성 (slug를 키로 사용)
// start, end는 ISO 문자열에서 Date 객체로 변환
export const tourDetails: { [key: string]: TourDetailData } = tourContext
  .keys()
  .reduce((acc: any, key: string) => {
    const data = tourContext(key);
    data.start = new Date(data.start);
    data.end = new Date(data.end);
    acc[data.slug] = data;
    return acc;
  }, {});

// Country별 투어 키 자동 생성 ("all"은 전체)
type Country = "all" | "korea" | "europe" | "japan";
const toursByCountry: { [key in Country]: string[] } = {
  all: Object.keys(tourDetails),
  korea: Object.keys(tourDetails).filter(
    (slug) => tourDetails[slug].country === "korea"
  ),
  europe: Object.keys(tourDetails).filter(
    (slug) => tourDetails[slug].country === "europe"
  ),
  japan: Object.keys(tourDetails).filter(
    (slug) => tourDetails[slug].country === "japan"
  ),
};

const countryNames = {
  ko: { all: "전체", korea: "한국", europe: "유럽", japan: "일본" },
  en: { all: "All", korea: "Korea", europe: "Europe", japan: "Japan" },
  ja: { all: "すべて", korea: "韓国", europe: "ヨーロッパ", japan: "日本" },
};

const Tour: React.FC = () => {
  const location = useLocation();
  const { language } = useLanguage();
  const navigate = useNavigate(); // eslint-disable-next-line
  const [currency, setCurrency] = useState<"USD" | "PHP" | "JPY" | "KRW">(
    "USD"
  );
  const [exchangeRates, setExchangeRates] = useState<{
    [key: string]: number;
  } | null>(null);
  const [showBookingModal, setShowBookingModal] = useState(false);

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const res = await axios.get(
          "https://v6.exchangerate-api.com/v6/63216fb46c1c0115608094a2/latest/USD"
        );
        setExchangeRates(res.data.conversion_rates);
      } catch (error) {
        console.error("Failed to fetch exchange rates:", error);
      }
    };
    fetchRates();
  }, []);

  // 기본 탭은 location.state의 선택된 국가 또는 "all"
  const [selectedCountry, setSelectedCountry] = useState<Country>(
    (location.state?.selectedCountry as Country) || "all"
  );
  const [selectedTour, setSelectedTour] = useState<string | null>(null);

  useEffect(() => {
    if (!["all", "korea", "europe", "japan"].includes(selectedCountry)) {
      setSelectedCountry("all");
    }
    setSelectedTour(null);
  }, [location.state, selectedCountry]);

  const seasonImages = {
    all: [
      "/assets/flags/WebImg/EUROPE1.jpeg",
      "/assets/flags/WebImg/KOREA1.jpeg",
      "/assets/flags/WebImg/JAPAN1.jpeg",
      "/assets/flags/WebImg/KOREA2.jpeg",
      "/assets/flags/WebImg/EUROPE3.jpeg",
      "/assets/flags/WebImg/JAPAN2.jpeg",
      "/assets/flags/WebImg/KOREA3.jpeg",
      "/assets/flags/WebImg/EUROPE2.jpeg",
      "/assets/flags/WebImg/JAPAN3.jpeg",
      "/assets/flags/WebImg/EUROPE4.jpg",
      "/assets/flags/WebImg/KOREA4.jpeg",
    ],
    korea: [
      "/assets/flags/WebImg/KOREA1.jpeg",
      "/assets/flags/WebImg/KOREA2.jpeg",
      "/assets/flags/WebImg/KOREA3.jpeg",
      "/assets/flags/WebImg/KOREA4.jpeg",
    ],
    europe: [
      "/assets/flags/WebImg/EUROPE1.jpeg",
      "/assets/flags/WebImg/EUROPE2.jpeg",
      "/assets/flags/WebImg/EUROPE3.jpeg",
      "/assets/flags/WebImg/EUROPE4.jpg",
    ],
    japan: [
      "/assets/flags/WebImg/JAPAN1.jpeg",
      "/assets/flags/WebImg/JAPAN2.jpeg",
      "/assets/flags/WebImg/JAPAN3.jpeg",
    ],
  };

  const convertPrice = (price: string): number => {
    return parseFloat(price.replace(/[^0-9]/g, "")) || 0;
  };
  // eslint-disable-next-line
  const priceInSelectedCurrency = (price: string) => {
    const priceInUsd = convertPrice(price);
    if (!exchangeRates) {
      return `${price} (Loading...)`;
    }
    switch (currency) {
      case "USD":
        return (
          priceInUsd.toLocaleString(undefined, { minimumFractionDigits: 0 }) +
          " USD"
        );
      case "KRW": {
        const rate = exchangeRates["KRW"];
        let converted = priceInUsd * rate;
        converted = Math.ceil(converted / 100) * 100;
        return (
          converted.toLocaleString(undefined, { minimumFractionDigits: 0 }) +
          " KRW"
        );
      }
      case "PHP": {
        const rate = exchangeRates["PHP"];
        let converted = priceInUsd * rate;
        converted = Math.ceil(converted / 100) * 100;
        return converted.toLocaleString(undefined) + " PHP";
      }
      case "JPY": {
        const rate = exchangeRates["JPY"];
        let converted = priceInUsd * rate;
        converted = Math.ceil(converted / 100) * 100;
        return (
          converted.toLocaleString(undefined, { minimumFractionDigits: 0 }) +
          " JPY"
        );
      }
      default:
        return price;
    }
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  const getSortedTourKeys = (): string[] => {
    return toursByCountry[selectedCountry].sort((a: string, b: string) => {
      const statusA = tourDetails[a].status;
      const statusB = tourDetails[b].status;
      return statusA === statusB ? 0 : statusA === "On" ? -1 : 1;
    });
  };
  const handleTourCardClick = (key: string) => {
    if (tourDetails[key].status === "Off") return;
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/tourDetails", { state: { selectedTour: key } });
  };

  return (
    <Container>
      <SliderWrapper>
        <StyledSlider {...sliderSettings}>
          {seasonImages[selectedCountry].map((image, index) => (
            <SlideImage key={index}>
              <img src={image} alt={`${selectedCountry} tour ${index + 1}`} />
            </SlideImage>
          ))}
        </StyledSlider>
      </SliderWrapper>

      <Title>TOURS</Title>

      <CountrySelector>
        {(["all", "korea", "europe", "japan"] as Country[]).map((country) => (
          <CountryButton
            key={country}
            isSelected={selectedCountry === country}
            onClick={() => {
              setSelectedCountry(country);
              setSelectedTour(null);
            }}
          >
            {countryNames[language][country]}
          </CountryButton>
        ))}
      </CountrySelector>

      {selectedTour === null ? (
        <TourCardContainer>
          {getSortedTourKeys().map((key) => {
            const info = tourDetails[key][language];
            const disabled = tourDetails[key].status === "Off";
            return (
              <TourCard
                key={key}
                disabled={disabled}
                bgImage={tourDetails[key].image}
                onClick={() => handleTourCardClick(key)}
              >
                <CardTitle>{info.title}</CardTitle>
                <CardTitle1>{info.subtitle}</CardTitle1>
                <CardTitle2>
                  {info.subtitle2}
                  {info.flagImage && (
                    <img
                      src={info.flagImage}
                      alt="Flag"
                      style={{ width: "15px", marginLeft: "5px" }}
                    />
                  )}
                  {info.flagImage2 && (
                    <img
                      src={info.flagImage2}
                      alt="Flag"
                      style={{ width: "15px", marginLeft: "5px" }}
                    />
                  )}
                  {info.flagImage3 && (
                    <img
                      src={info.flagImage3}
                      alt="Flag"
                      style={{ width: "15px", marginLeft: "5px" }}
                    />
                  )}
                </CardTitle2>
                <CardTitle3>{info.period}</CardTitle3>
                {disabled && <OffBadge>COMING SOON!</OffBadge>}
              </TourCard>
            );
          })}
        </TourCardContainer>
      ) : (
        <TourDetails>
          <BackButton onClick={() => setSelectedTour(null)}>&lt;</BackButton>
          {(() => {
            const data = tourDetails[selectedTour];
            const info = data[language];
            return (
              <>
                <h1 style={{ color: "#ffd700", textAlign: "center" }}>
                  {info.title}
                  <br />
                </h1>
                <h2
                  style={{
                    color: "#ffd700",
                    textAlign: "center",
                    marginTop: "-15px",
                  }}
                >
                  {info.subtitle}
                </h2>
                <p style={{ textAlign: "center", color: "#ffffff" }}>
                  <b>{info.subtitle2}</b>
                  <img
                    src={info.flagImage}
                    alt=""
                    style={{
                      width: "15px",
                      marginLeft: "5px",
                      alignItems: "center",
                    }}
                  />
                  <img
                    src={info.flagImage2}
                    alt=""
                    style={{
                      width: "15px",
                      marginLeft: "5px",
                      alignItems: "center",
                    }}
                  />
                  <img
                    src={info.flagImage3}
                    alt=""
                    style={{
                      width: "15px",
                      marginLeft: "5px",
                      alignItems: "center",
                    }}
                  />
                </p>
                <p style={{ textAlign: "center", color: "#ccc" }}>
                  {info.period}
                </p>
                <p
                  style={{
                    textAlign: "center",
                    color: "#ffd700",
                    fontSize: "1.2rem",
                  }}
                >
                  {data.price} USD
                </p>
                <Section>
                  <SectionTitle>Tour Highlights</SectionTitle>
                  <List>
                    {info.highlights.map((h, i) => (
                      <ListItem key={i}>{h}</ListItem>
                    ))}
                  </List>
                </Section>
                <Section>
                  <SectionTitle>Includes</SectionTitle>
                  <List>
                    {info.includes.map((inc, i) => (
                      <ListItem key={i}>{inc}</ListItem>
                    ))}
                  </List>
                </Section>
                <Description>{info.description}</Description>
                <ButtonContainer>
                  <ActionButton
                    onClick={() =>
                      window.open(info.pdfLink, "_blank", "noopener,noreferrer")
                    }
                  >
                    More Details
                  </ActionButton>
                  <ActionButton onClick={() => setShowBookingModal(true)}>
                    Book This Tour
                  </ActionButton>
                </ButtonContainer>
              </>
            );
          })()}
        </TourDetails>
      )}

      {/* 모달(팝업) */}
      {showBookingModal && (
        <ModalOverlay onClick={() => setShowBookingModal(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={() => setShowBookingModal(false)}>
              &times;
            </CloseButton>
            <ModalItemContainer>
              <ModalItem
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/momento_korea/",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              >
                <img
                  src={InstagramIco}
                  alt="Instagram"
                  style={{ width: "24px" }}
                />
                <ModalItemText>INSTAGRAM</ModalItemText>
              </ModalItem>
              <ModalItem
                onClick={() =>
                  window.open(
                    "https://wa.me/your_whatsapp_number",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              >
                <img
                  src={WhatsappIco}
                  alt="WhatsApp"
                  style={{ width: "24px" }}
                />
                <ModalItemText>WHATS APP</ModalItemText>
              </ModalItem>
              <ModalItem
                onClick={() => {
                  navigate("/contact");
                  setShowBookingModal(false);
                }}
              >
                {/* <span role="img" aria-label="chat" style={{ fontSize: "24px" }}>
                  💬
                </span> */}

                <img src={ChatIco} alt="WhatsApp" style={{ width: "24px" }} />
                <ModalItemText>GET A QUOTE</ModalItemText>
              </ModalItem>
            </ModalItemContainer>
            <FooterText>
              {"Still have questions? => "}
              <ClickableText
                onClick={() => {
                  navigate("/faq");
                  setShowBookingModal(false);
                }}
              >
                CHECK OUT FAQ
              </ClickableText>
            </FooterText>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default Tour;

/* ------------------ styled-components ------------------ */

const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom, #000000, #1a1a1a);
  color: #fff;
  padding: 120px 20px;
`;

const SliderWrapper = styled.div`
  margin: -120px -20px 3rem -20px;
  height: 60vh;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(to top, #000000, transparent);
    pointer-events: none;
  }
`;

const StyledSlider = styled(Slider)`
  height: 100%;
  .slick-list,
  .slick-track {
    height: 100%;
  }
  .slick-dots {
    bottom: 30px;
    z-index: 1;
    li button:before {
      color: #ffd700;
    }
    li.slick-active button:before {
      color: #ffd700;
    }
  }
  .slick-prev,
  .slick-next {
    z-index: 1;
    width: 40px;
    height: 40px;
    &:before {
      font-size: 40px;
    }
  }
  .slick-prev {
    left: 20px;
  }
  .slick-next {
    right: 20px;
  }
`;

const SlideImage = styled.div`
  height: 60vh;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px;
  font-family: "Gruppo", sans-serif;
  margin-top: 100px;
`;

const CountrySelector = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

const CountryButton = styled.button<{ isSelected: boolean }>`
  padding: 1rem 2rem;
  background: ${({ isSelected }) =>
    isSelected ? "rgba(255, 215, 0, 0.1)" : "transparent"};
  border: 2px solid
    ${({ isSelected }) => (isSelected ? "#ffd700" : "rgba(255, 255, 255, 0.3)")};
  color: ${({ isSelected }) => (isSelected ? "#ffd700" : "#fff")};
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border-color: #ffd700;
    color: #ffd700;
  }
`;

const TourCardContainer = styled.div`
  display: grid;
  gap: 2rem;
  margin-bottom: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  @media (min-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

interface TourCardProps {
  disabled: boolean;
  bgImage: string;
}

const TourCard = styled.div<TourCardProps>`
  background: url(${(props) => props.bgImage}) no-repeat center center/cover;
  position: relative;
  border: 1px solid rgba(255, 215, 0, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")} !important;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    transition: background 0.3s ease;
    z-index: 0;
  }

  &:hover::before {
    background: rgba(0, 0, 0, 0.2);
  }

  > * {
    position: relative;
    z-index: 1;
    cursor: inherit;
  }

  &:hover {
    cursor: ${(props) =>
      props.disabled ? "not-allowed" : "pointer"} !important;
    transform: ${(props) => (props.disabled ? "none" : "translateY(-5px)")};
    box-shadow: ${(props) =>
      props.disabled ? "none" : "0 5px 15px rgba(255, 215, 0, 0.3)"};
  }
`;

const CardTitle = styled.h3`
  color: #ffd700;
  font-weight: 800;
  font-size: 1.2rem;
  margin-top: 0px;
  margin-bottom: -15px;
`;

const CardTitle1 = styled.h3`
  color: #ffd700;
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;

const CardTitle2 = styled.h3`
  color: #ffffff;
  font-size: 1rem;
  margin-bottom: -10px;

  @media (max-width: 400px) {
    font-size: 0.8rem;
  }
`;

const CardTitle3 = styled.h3`
  color: #ffffff;
  font-size: 0.8rem;
  margin-bottom: 0rem;
`;

const OffBadge = styled.div`
  margin-top: 1rem;
  color: #ffffff;
  text-align: right;
  font-weight: bold;
`;

const TourDetails = styled.div`
  max-width: 900px;
  margin: 0 auto 3rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 3rem;
  border: 1px solid rgba(255, 215, 0, 0.2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  position: relative;
`;

const BackButton = styled.button`
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 1.5rem;
  background: transparent;
  color: #ffd700;
  border: none;
  cursor: pointer;
  &:hover {
    color: #ffa500;
  }
`;

const Section = styled.div`
  margin: 2rem 0;
`;

const SectionTitle = styled.h3`
  font-size: 1.5rem;
  color: #ffd700;
  margin-bottom: 1rem;
  text-align: center;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin: 0.5rem 0;
  padding-left: 1rem;
  position: relative;
  &::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #ffd700;
  }
`;

const Description = styled.p`
  color: #ccc;
  font-size: 1rem;
  line-height: 1.6;
  margin: 2rem 0;
  text-align: center;
  font-style: italic;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const ActionButton = styled.button`
  display: block;
  width: 100%;
  max-width: 180px;
  margin: 0 auto;
  padding: 0.8rem 1.5rem;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  border: none;
  border-radius: 50px;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
  }
  @media (min-width: 769px) {
    max-width: 220px;
    font-size: 1.1rem;
  }
`;

/* ------------- 모달(팝업) 관련 styled-components ------------- */
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const ModalContent = styled.div`
  background: #fff; /* 배경을 검정으로 변경 */
  color: #000f; /* 글자색을 흰색으로 변경해서 가독성 확보 */
  border-radius: 10px;
  padding: 2rem;
  position: relative;
  width: 90%;
  max-width: 300px;
  text-align: center; /* 텍스트 중앙 정렬 */
`;

const ModalItemContainer = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  gap: 1rem;
  align-items: center; /* 아이템들을 수평 중앙 정렬 */
  margin-top: 10px;
`;

const ModalItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; /* 아이템 내 요소들도 중앙 정렬 */
  gap: 0.5rem;
  margin-top: 10px;
  cursor: pointer;
`;

const ModalItemText = styled.span`
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

const FooterText = styled.p`
  font-size: 0.8rem;
  margin-top: 1rem;
`;

const ClickableText = styled.span`
  color: #007bff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
