import React, { useState, useEffect, useRef } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";
import { useLanguage } from "../contexts/LanguageContext";
// import { breakpoints } from "../styles/constants";
import { useNavigate } from "react-router-dom";
// 투어 이벤트 JSON 데이터 import (각 JSON 파일에는 start/end가 ISO 문자열로 저장됨)
import GermanySwitzerland from "../pages/data/Aa-germany-switzerland.json";
import Switzerland from "../pages/data/Ab-switzerland.json";
import France from "../pages/data/Ac-france.json";
import FINLANDSWEDENNORWAY from "../pages/data/Ad-FINLAND,SWEDEN,NORWAY.json";

import GermanySwitzerlandAustria from "../pages/data/Ba-germany-switzerland-austria.json";
import Jeju from "../pages/data/Bb-jeju.json";

import Sapporo from "../pages/data/Ca-sapporo.json";
import Gangneung from "../pages/data/Bc-gangneung.json";
// JSON 데이터에서 start, end를 Date 객체로 변환
const tourEvents: any[] = [
  GermanySwitzerland,
  Switzerland,
  France,
  FINLANDSWEDENNORWAY,

  GermanySwitzerlandAustria,
  Jeju,

  Sapporo,
  Gangneung,
].map((evt: any) => {
  const startDate = new Date(evt.start);
  const endDate = new Date(evt.end);

  return {
    ...evt,
    start: new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      0,
      0,
      0
    ), // 로컬 시간 기준 변환
    end: new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      23,
      59,
      59
    ), // 날짜 범위 정확하게 맞춤
  };
});

// status가 "Off"인 이벤트는 제외하고, 나머지 이벤트들(activeEvents)만 사용
const activeEvents = tourEvents.filter((evt: any) => evt.status !== "Off");

// 라벨용 번역 객체 (이벤트 데이터는 activeEvents 사용)
const translations = {
  ko: {
    goToday: "오늘로 이동",
    monthTitle: (y: number, m: number) => `${y}년 ${m}월 일정`,
    consult: "더보기",
    noEvents: "이 달에 등록된 이벤트가 없습니다.",
    summaryLabel: "요약 :",
    includedLabel: "포함 사항 :",
    book: "예약 문의",
  },
  en: {
    goToday: "Go Today",
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthTitle: (y: number, m: number, labels: any) =>
      `Events in ${labels.monthNames[m - 1]}/${y}`,
    consult: "More",
    noEvents: "No events found for this month.",
    summaryLabel: "SUMMARY :",
    includedLabel: "WHAT IS INCLUDED :",
    book: "Book This Tour",
  },
  ja: {
    goToday: "今日に移動",
    monthTitle: (y: number, m: number) => `${y}年${m}月の予定`,
    consult: "もっと見る",
    noEvents: "この月に登録されたイベントはありません。",
    summaryLabel: "概要 :",
    includedLabel: "含まれるもの :",
    book: "お問い合わせ",
  },
};

function isWithinRange(day: Date, start: Date, end: Date) {
  return day >= start && day <= end;
}

function areRangesOverlapping(
  start1: Date,
  end1: Date,
  start2: Date,
  end2: Date
) {
  return start1 <= end2 && end1 >= start2;
}

const CalendarComponent: React.FC = () => {
  const { language } = useLanguage();
  const labels = translations[language];

  // activeStartDate는 달력의 현재 표시 월을 의미 (초기값은 오늘)
  const [activeStartDate, setActiveStartDate] = useState<Date>(new Date());
  const [openedEventSlug, setOpenedEventSlug] = useState<string | null>(null);
  const [hoveredEventSlug, setHoveredEventSlug] = useState<string | null>(null);
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // 달력에서 표시할 월 범위 (activeStartDate 기준)
  const currentYear = activeStartDate.getFullYear();
  const currentMonth = activeStartDate.getMonth();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  const firstDayOfNextMonth = new Date(currentYear, currentMonth + 1, 1);
  const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1);

  // 이번 달 이벤트 (activeEvents 중, 현재 달 범위에 겹치는 이벤트) → 시작일 오름차순 정렬
  const monthEvents = activeEvents
    .filter((evt: any) =>
      areRangesOverlapping(evt.start, evt.end, firstDayOfMonth, lastDayOfMonth)
    )
    .sort((a: any, b: any) => a.start.getTime() - b.start.getTime());

  const handleActiveStartDateChange = ({ activeStartDate }: any) => {
    if (activeStartDate) {
      setActiveStartDate(activeStartDate);
    }
  };

  const handleGoToday = () => {
    setActiveStartDate(new Date());
  };

  // 날짜 클릭 시, 해당 날짜에 포함되는 이벤트의 토글 처리
  const handleDateClick = (value: Date) => {
    const event = activeEvents.find((evt: any) =>
      isWithinRange(value, evt.start, evt.end)
    );
    if (event) {
      setOpenedEventSlug((prev) => (prev === event.slug ? null : event.slug));
    } else {
      setOpenedEventSlug(null);
    }
  };

  // tileContent: 날짜 타일에 마우스 진입 시, 해당 날짜에 포함되는 이벤트가 있다면 그 이벤트의 slug를 저장
  const tileContent = ({ date, view }: { date: Date; view: string }) => {
    if (view === "month") {
      const eventForTile = activeEvents.find((evt: any) =>
        isWithinRange(date, evt.start, evt.end)
      );
      return (
        <TileHoverArea
          hasEvent={!!eventForTile}
          onMouseEnter={() => {
            if (eventForTile) setHoveredEventSlug(eventForTile.slug);
            setHoveredDate(date);
          }}
          onMouseLeave={() => {
            setHoveredEventSlug(null);
            setHoveredDate(null);
          }}
        />
      );
    }
    return null;
  };

  // getTileClassName: 각 날짜 타일에 적용할 클래스 (JSON 이벤트 색상 및 호버 시 검정 테두리)
  const getTileClassName = ({ date, view }: { date: Date; view: string }) => {
    if (view === "month") {
      const classes: string[] = [];
      const matchedEvents = activeEvents.filter((evt: any) =>
        isWithinRange(date, evt.start, evt.end)
      );
      if (matchedEvents.length > 0) {
        matchedEvents.forEach((evt: any) => {
          classes.push(`highlight-${evt.slug}`);
          if (hoveredEventSlug === evt.slug) {
            classes.push("hovered-event");
          }
        });
        classes.push("has-event");
      } else {
        classes.push("no-event");
      }
      return classes.join(" ");
    }
    return null;
  };

  const handleEventClick = (slug: string) => {
    setOpenedEventSlug((prev) => (prev === slug ? null : slug));
  };

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (!containerRef.current) return;
      if (!containerRef.current.contains(e.target as Node)) {
        setOpenedEventSlug(null);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  // 줄바꿈 처리 함수
  // eslint-disable-next-line
  const formatShortDesc = (desc: string) => {
    return desc.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  const navigate = useNavigate();

  return (
    <CalendarPageContainer ref={containerRef}>
      <CalendarBox>
        <CustomCalendarWrapper>
          <Calendar
            locale={
              language === "ko"
                ? "ko-KR"
                : language === "ja"
                ? "ja-JP"
                : "en-US"
            }
            calendarType="gregory"
            selectRange={false}
            onActiveStartDateChange={handleActiveStartDateChange}
            activeStartDate={activeStartDate}
            tileClassName={getTileClassName}
            onClickDay={handleDateClick}
            tileContent={({ date, view }) =>
              view === "month" ? tileContent({ date, view }) : null
            }
          />
        </CustomCalendarWrapper>
        <TodayButton onClick={handleGoToday}>{labels.goToday}</TodayButton>
      </CalendarBox>

      <EventsBox>
        <MonthTitle>
          {labels.monthTitle(currentYear, currentMonth + 1, labels)}
        </MonthTitle>
        {monthEvents.length > 0 ? (
          <MonthEventsContainer>
            {monthEvents.map((evt: any) => {
              const isOpened = openedEventSlug === evt.slug;
              const dateHoveredThisEvent =
                hoveredDate && isWithinRange(hoveredDate, evt.start, evt.end);
              const isHoveredEvent = hoveredEventSlug === evt.slug;
              return (
                <MonthEventItem
                  key={evt.slug}
                  color={evt.color}
                  isOpened={isOpened}
                  onMouseEnter={() => setHoveredEventSlug(evt.slug)}
                  onMouseLeave={() => setHoveredEventSlug(null)}
                  onClick={() => handleEventClick(evt.slug)}
                  className={
                    dateHoveredThisEvent || isHoveredEvent
                      ? "hovered-event"
                      : ""
                  }
                >
                  <EventHeader>
                    <EventTitle>{evt[language].title}</EventTitle>
                    <EventDateRange>
                      {evt.start.toLocaleDateString()} ~{" "}
                      {evt.end.toLocaleDateString()}
                    </EventDateRange>
                  </EventHeader>
                  <DropdownInfo isOpened={isOpened}>
                    {/* 추가 정보: subtitle, period, highlights, includes, description */}
                    {/* {evt[language].subtitle && (
                      <p>
                        <strong>{evt[language].subtitle}</strong>
                      </p>
                    )} */}
                    {evt[language].highlights &&
                      evt[language].highlights.length > 0 && (
                        <div>
                          <strong>{labels.summaryLabel}</strong>
                          <ul>
                            {evt[language].highlights.map(
                              (item: string, idx: number) => (
                                <li key={idx}>{item}</li>
                              )
                            )}
                          </ul>
                        </div>
                      )}
                    {evt[language].includes &&
                      evt[language].includes.length > 0 && (
                        <div>
                          <strong>{labels.includedLabel}</strong>
                          <ul>
                            {evt[language].includes.map(
                              (item: string, idx: number) => (
                                <li key={idx}>{item}</li>
                              )
                            )}
                          </ul>
                        </div>
                      )}
                    {/* <ShortDescription>
                      {evt[language].shortDesc
                        ? formatShortDesc(evt[language].shortDesc)
                        : formatShortDesc(evt[language].description)}
                    </ShortDescription> */}
                    <ConsultButton
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(evt[language].pdfLink, "_blank");
                      }}
                    >
                      {labels.consult}
                    </ConsultButton>{" "}
                    <ConsultButton onClick={() => navigate("/contact")}>
                      {labels.book}
                    </ConsultButton>
                  </DropdownInfo>
                </MonthEventItem>
              );
            })}
          </MonthEventsContainer>
        ) : (
          <NoEventNotice>{labels.noEvents}</NoEventNotice>
        )}
      </EventsBox>
    </CalendarPageContainer>
  );
};

export default CalendarComponent;

/* ----------------------- Styled Components ----------------------- */

const CalendarPageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem 1rem;
  gap: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap-reverse;
    padding: 1rem;
  }
`;

const CalendarBox = styled.div`
  flex: 1 1 300px;
  min-width: 300px;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 1.5rem 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  position: relative;
  @media (max-width: 768px) {
    font-size: 0.95rem;
    padding: 0.5rem;
    background: none;
  }
`;

const CustomCalendarWrapper = styled.div`
  .react-calendar {
    position: relative;
    border: none;
    width: 100%;
    max-width: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    @media (max-width: 768px) {
      font-size: 0.95rem;
      padding: 0.5rem;
    }
    .react-calendar__month-view__weekdays__weekday {
      color: #b55;
      font-weight: 600;
      text-align: center;
    }
    .react-calendar__tile {
      position: relative;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
    .react-calendar__tile--now {
      background: #ffe2e2;
      border-radius: 4px;
    }
    .react-calendar__tile--active {
      background: #ffffff;
      color: black;
      border-radius: 4px;
      &:hover {
        background: #f1943f;
      }
    }
    .react-calendar__tile:hover {
      background: #eee;
      border-radius: 4px;
    }
    /* JSON 이벤트 색상 적용 */
    ${tourEvents
      .map(
        (evt: any) =>
          `.react-calendar__tile.highlight-${evt.slug} { background-color: ${evt.color} !important; border-radius: 6px; }`
      )
      .join("\n")}
    .react-calendar__tile.has-multiple-events::after {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      height: 4px;
      background: linear-gradient(
        to right,
        lightgreen 33%,
        lightskyblue 33%,
        lightskyblue 66%,
        lightyellow 66%
      );
      border-radius: 2px;
    }
    /* 호버 시 검정 테두리 (hovered-event 클래스) */
    .react-calendar__tile.hovered-event {
      border: 2px solid black !important;
    }
    .react-calendar__tile.has-event {
      cursor: pointer;
    }
    .react-calendar__tile.no-event {
      cursor: default;
    }
  }
`;

const TileHoverArea = styled.div<{ hasEvent: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const TodayButton = styled.button`
  display: block;
  margin: 1rem auto 0;
  padding: 0.6rem 1.2rem;
  border: none;
  background: #3b82f6;
  color: white;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  &:hover {
    background: #2563eb;
  }
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const EventsBox = styled.div`
  flex: 1 1 300px;
  min-width: 300px;
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  position: relative;
  max-height: 470px;
  overflow-y: auto;
  transition: all 0.3s ease;
  @media (max-width: 768px) {
    font-size: 0.95rem;
    padding: 1rem;
    background: none;
  }
`;

const MonthTitle = styled.h2`
  margin: 0;
  margin-bottom: 1rem;
  color: #333;
  font-size: 1.2rem;
  @media (max-width: 768px) {
    font-size: 1.1rem;
    color: #fff;
  }
`;

const MonthEventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MonthEventItem = styled.div<{ color: string; isOpened: boolean }>`
  background-color: ${(props) => props.color || "#fff"};
  border-radius: 8px;
  cursor: pointer;
  transition: border 0.2s;
  color: #004225;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  &:hover {
    border: 2px solid #004225;
  }
  &.hovered-event {
    border: 2px solid #004225;
  }
`;

const EventHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const EventTitle = styled.span`
  font-weight: 600;
  font-size: 1rem;
  flex: 1;
`;

const EventDateRange = styled.span`
  font-size: 0.9rem;
  color: #555;
`;

const ConsultButton = styled.button`
  padding: 0.5rem 1rem;
  background: #f47b20;
  border: none;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
  &:hover {
    background: #f15b00;
  }
`;

const DropdownInfo = styled.div<{ isOpened: boolean }>`
  max-height: ${(props) => (props.isOpened ? "350px" : "0")};
  opacity: ${(props) => (props.isOpened ? "1" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  padding: ${(props) => (props.isOpened ? "1rem" : "0 1rem")};
`;

// eslint-disable-next-line
const ShortDescription = styled.p`
  margin: 0 0 0.5rem 0;
  font-size: 0.9rem;
  line-height: 1.4;
`;

const NoEventNotice = styled.p`
  margin-top: 1rem;
  color: #999;
  font-size: 0.95rem;
`;
