import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/constants";
import { useLanguage } from "../contexts/LanguageContext";
import { useNavigate } from "react-router-dom";

import Img9 from "../assets/po.jpeg";
import backgroundVideo from "../assets/video/1.mp4";

const translations = {
  en: {
    title: "Momento",
    subtitle: "Luxury and Adventure in Every Moment.",
    description:
      "The ultimate travel experience, prepared for your comfort and ease.\n\nYour journey begins with you riding dream  car,\nAnd ends with perfect relaxation and exploration in a new dimension.",
    ctaButton: "Contact Us",
    features: {
      car: {
        title: "Premium Vehicles",
        text: "Luxury & Sports Car Lineup",
      },
      route: {
        title: "Custom Routes",
        text: "Special driving courses from city to coast",
      },
      hotel: {
        title: "Luxury Stay",
        text: "Premium Hotels & Resorts",
      },
    },
    impactText: "Experience Luxury Beyond Imagination",
    popuptext: "EUROPE\nHORIZON 2",
    popuptext2: "March 26th, 2025\n~ April 5th, 2025",
  },
  ko: {
    title: "Momento",
    subtitle: "모든 순간, 럭셔리와 모험을 함께.",
    description:
      "당신의 편안함과 여유를 위해 준비된, 최고의 여행 경험.\n\n여행의 시작은 드림카를 타고, 끝은 새로운 차원에서의 \n완벽한 휴식과 탐험으로 이어집니다.\n\n Life with memories of moment, Momento Becomes Dream.",
    ctaButton: "문의하기",
    features: {
      car: {
        title: "프리미엄 차량",
        text: "최고급 럭셔리 & 스포츠카 라인업",
      },
      route: {
        title: "맞춤 루트",
        text: "도심부터 해안까지 특별한 드라이브 코스",
      },
      hotel: {
        title: "럭셔리 숙박",
        text: "최상급 호텔 & 리조트",
      },
    },
    impactText: "상상을 초월하는 럭셔리를 경험하세요",
    popuptext: "유럽\n호라이즌 2",
    popuptext2: "20205년 3월 26일\n~ 2025년 4월 6일",
  },
  ja: {
    title: "モメント",
    subtitle: "すべての瞬間を、ラグジュアリーと冒険とともに。",
    description:
      "あなたの快適さとゆとりのために用意された、\n最高の旅行体験。\n\n 旅の始まりはドリームカーに乗り、\n 終わりは新たな次元での完璧な休息と探検へと続きます。\n\nLife with memories of moment, Momento Becomes Dream.",
    ctaButton: "お問い合わせ",
    features: {
      car: {
        title: "プレミアム車両",
        text: "最高級ラグジュアリー & スポーツカー",
      },
      route: {
        title: "カスタムルート",
        text: "都市から海岸まで特別なドライブコース",
      },
      hotel: {
        title: "ラグジュアリーな宿泊",
        text: "最上級ホテル & リゾート",
      },
    },
    impactText: "想像を超えるラグジュアリーを体験",
    popuptext: "ヨーロッパ\nホライゾン2",
    popuptext2: "2025 年 3 月 26 日\n～2025年4月5日",
  },
};

const Home = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);

  const t = translations[language];

  useEffect(() => {
    const lastPopupTime = localStorage.getItem("lastPopupTime");
    const currentTime = new Date().getTime();

    if (
      !lastPopupTime ||
      currentTime - parseInt(lastPopupTime) > 12 * 60 * 60 * 1000
    ) {
      setShowPopup(true);
    }
  }, []);

  const handleClosePopup = (dontShowToday = false) => {
    if (dontShowToday) {
      localStorage.setItem("lastPopupTime", new Date().getTime().toString());
    }
    setShowPopup(false);
  };

  const handleMoreInfo = () => {
    navigate("/tourDetails", {
      state: { selectedTour: "SWITZERLAND", language },
    });
    setShowPopup(false);
  };

  useEffect(() => {
    const video = document.querySelector("video");
    if (video) {
      // 비디오 재생 속도
      video.playbackRate = 1;
    }
  }, []);

  return (
    <>
      <Container>
        <HeroSection>
          <VideoBackground autoPlay muted loop playsInline>
            <source src={backgroundVideo} type="video/mp4" />
          </VideoBackground>
          <Overlay>
            <HeroContent>
              <Subtitle>{t.subtitle}</Subtitle>
              <Description>
                {t.description.split("\n").map((line: string, i: number) => (
                  <React.Fragment key={i}>
                    {line}
                    {i !== t.description.split("\n").length - 1 && <br />}
                  </React.Fragment>
                ))}
              </Description>
              <CTAButton onClick={() => navigate("/contact")}>
                {t.ctaButton}
              </CTAButton>
            </HeroContent>
          </Overlay>
        </HeroSection>

        <FeaturesSection>
          <FeatureCard>
            <FeatureIcon>🚗</FeatureIcon>
            <FeatureTitle>{t.features.car.title}</FeatureTitle>
            <FeatureText>{t.features.car.text}</FeatureText>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon>🌊</FeatureIcon>
            <FeatureTitle>{t.features.route.title}</FeatureTitle>
            <FeatureText>{t.features.route.text}</FeatureText>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon>🏨</FeatureIcon>
            <FeatureTitle>{t.features.hotel.title}</FeatureTitle>
            <FeatureText>{t.features.hotel.text}</FeatureText>
          </FeatureCard>
        </FeaturesSection>
      </Container>

      {showPopup && (
        <>
          <PopupOverlay onClick={() => handleClosePopup(false)} />
          <Popup>
            <CloseButton onClick={() => handleClosePopup(false)}>×</CloseButton>
            <PopupContent>
              <Textsec>
                <PopupTitle>{t.popuptext}</PopupTitle>
                <PopupSubtitle>{t.popuptext2}</PopupSubtitle>
              </Textsec>
              <LearnMoreButton onClick={handleMoreInfo}>
                {language === "ko"
                  ? "자세히 보기"
                  : language === "ja"
                  ? "詳しく見る"
                  : "Learn More"}
              </LearnMoreButton>
            </PopupContent>
            <DontShowButton onClick={() => handleClosePopup(true)}>
              {language === "ko"
                ? "오늘 하루 보지 않기"
                : language === "ja"
                ? "今日一日は表示しない"
                : "Don't show today"}
            </DontShowButton>
          </Popup>
        </>
      )}
    </>
  );
};

export default Home;

/* ---------------- Styled Components --------------- */

const Container = styled.div`
  width: 100%;
  margin-top: 0px;
`;

const HeroSection = styled.div`
  height: 100vh;
  position: relative;
  overflow: hidden;

  @media (max-width: ${breakpoints.mobile}) {
    height: 90vh;
  }
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeroContent = styled.div`
  text-align: center;
  color: white;
  padding: 0 20px;
  max-width: 800px;
  margin: 0 auto;
`;

const Subtitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 1.6;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 1rem;
  }
`;

const CTAButton = styled.button`
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background-color: #ffd700;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    width: 100%;
    max-width: 280px;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const FeaturesSection = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 5rem 2rem;
  background-color: #f8f8f8;

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    align-items: center;
    padding: 3rem 1rem;
    gap: 2rem;
  }

  @media (max-width: ${breakpoints.tablet}) and (min-width: ${breakpoints.mobile}) {
    flex-wrap: wrap;
    gap: 2rem;
  }
`;

const FeatureCard = styled.div`
  text-align: center;
  padding: 2rem;
  width: 300px;
  transition: transform 0.3s ease;

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    padding: 1.5rem;
    max-width: 280px;
  }

  &:hover {
    transform: translateY(-5px);
  }
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const FeatureText = styled.p`
  font-size: 1rem;
  color: #666;
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 100px;
  transform: translateY(-50%);
  background: #1a1a1a;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 400px;
  width: 90%;
  max-height: 90vh;
  overflow: hidden;

  @media (max-width: ${breakpoints.mobile}) {
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
  }
`;

const PopupContent = styled.div`
  position: relative;
  text-align: center;
  background: #1a1a1a;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  background-image: url(${Img9});
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 250px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px 10px 0 0;
  }
`;

const Textsec = styled.div`
  width: 100%;
  height: 50px;
  @media (max-width: ${breakpoints.mobile}) {
  }
`;

const PopupTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  /* margin-bottom: 10px; */
  color: #ffa730;
  position: relative;
  z-index: 1;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 20px;
  }
`;
// eslint-disable-next-line
const PopupSubtitle = styled.h3`
  font-size: 16px;
  /* margin-bottom: 20px; */
  color: white;
  position: relative;
  z-index: 1;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 16px;
  }
`;

const LearnMoreButton = styled.button`
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  background: #ffa730;
  color: white;
  margin-top: 20px;
  z-index: 1;
  position: relative;

  &:hover {
    background: #ff9610;
  }
`;

const DontShowButton = styled.button`
  width: 100%;
  padding: 10px;
  border: none;
  background: transparent;
  color: #999;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: #666;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border: none;
  background: transparent;
  color: white;
  font-size: 20px;
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;
