import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { useLanguage } from "../contexts/LanguageContext";
import InstagramIco from "../assets/Instagram.png";
import WhatsappIco from "../assets/WhatsApp.png";
import ChatIco from "../assets/chat.png";
import { tourDetails } from "../pages/Tour";

const TourDetailsPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { language } = useLanguage();

  // location.state에서 선택된 투어와 언어 정보를 추출
  const selectedTour = location.state?.selectedTour;
  const lang: "ko" | "en" | "ja" =
    (location.state?.language as "ko" | "en" | "ja") || language || "en";

  const [showBookingModal, setShowBookingModal] = useState(false);

  useEffect(() => {
    if (!selectedTour || !tourDetails[selectedTour]) {
      navigate("/tours");
    }
  }, [selectedTour, navigate]);

  if (!selectedTour || !tourDetails[selectedTour]) {
    return null;
  }

  const data = tourDetails[selectedTour];
  const info = data[lang];

  // 일정 정보가 메타데이터에 추가로 있다면 schedules 배열로 구성
  const schedules: { start: string; end: string }[] = [];
  if (data.start && data.end) {
    // data.start와 data.end는 Date 객체라 가정하고 locale string으로 변환
    schedules.push({
      start: data.start.toLocaleDateString(),
      end: data.end.toLocaleDateString(),
    });
  }
  // 나머지 일정은 문자열로 전달되었다고 가정 (타입 체크를 위해 as any 사용)
  if ((data as any).start2 && (data as any).end2) {
    schedules.push({
      start: (data as any).start2,
      end: (data as any).end2,
    });
  }
  if ((data as any).start3 && (data as any).end3) {
    schedules.push({
      start: (data as any).start3,
      end: (data as any).end3,
    });
  }
  if ((data as any).start4 && (data as any).end4) {
    schedules.push({
      start: (data as any).start4,
      end: (data as any).end4,
    });
  }
  const getSchedulePrice = (index: number): string => {
    switch (index) {
      case 0:
        return data.price;
      case 1:
        return (data as any).price2 || data.price;
      case 2:
        return (data as any).price3 || data.price;
      case 3:
        return (data as any).price4 || data.price;
      default:
        return data.price;
    }
  };

  return (
    <DetailsContainer>
      <TourDetailsContainer>
        <BackButton onClick={() => navigate("/tours")}>&lt;</BackButton>
        <h1 style={{ color: "#ffd700", textAlign: "center" }}>
          {info.title}
          <br />
        </h1>
        <h2
          style={{ color: "#ffd700", textAlign: "center", marginTop: "-15px" }}
        >
          {info.subtitle}
        </h2>
        <p style={{ textAlign: "center", color: "#ffffff" }}>
          <b>{info.subtitle2}</b>
          {info.flagImage && (
            <img
              src={info.flagImage}
              alt="Flag"
              style={{ width: "15px", marginLeft: "5px" }}
            />
          )}
          {info.flagImage2 && (
            <img
              src={info.flagImage2}
              alt="Flag"
              style={{ width: "15px", marginLeft: "5px" }}
            />
          )}
          {info.flagImage3 && (
            <img
              src={info.flagImage3}
              alt="Flag"
              style={{ width: "15px", marginLeft: "5px" }}
            />
          )}
        </p>
        <p style={{ textAlign: "center", color: "#ccc" }}>{info.period}</p>
        <p
          style={{
            textAlign: "center",
            color: "#ffd700",
            fontSize: "1.2rem",
          }}
        >
          {data.price} USD
        </p>
        <Section>
          <SectionTitle>Tour Highlights</SectionTitle>
          <List>
            {info.highlights.map((h: string, i: number) => (
              <ListItem key={i}>{h}</ListItem>
            ))}
          </List>
        </Section>
        <Section>
          <SectionTitle>Includes</SectionTitle>
          <List>
            {info.includes.map((inc: string, i: number) => (
              <ListItem key={i}>{inc}</ListItem>
            ))}
          </List>
        </Section>
        <Description>{info.description}</Description>
        <ButtonContainer>
          <ActionButton
            onClick={() =>
              window.open(info.pdfLink, "_blank", "noopener,noreferrer")
            }
          >
            More Details
          </ActionButton>
          <ActionButton onClick={() => setShowBookingModal(true)}>
            Get in Touch
          </ActionButton>
        </ButtonContainer>
      </TourDetailsContainer>

      {/* 새로운 일정 섹션 */}
      <ScheduleContainer>
        {schedules.length > 0 && (
          <ScheduleSection>
            <ScheduleFirstTitle>Schedules</ScheduleFirstTitle>
            {schedules.map((schedule, idx) => (
              <ScheduleCard key={idx}>
                <ScheduleTitle>{info.title}</ScheduleTitle>
                <ScheduleText>
                  {schedule.start} ~ {schedule.end}
                </ScheduleText>
                <SchedulePrice>{getSchedulePrice(idx)} USD</SchedulePrice>
                <ScheduleButton
                  onClick={() =>
                    navigate("/contact", {
                      state: {
                        prefillMessage: `${info.title}\n${
                          info.subtitle
                        }\nSchedule: ${schedule.start} ~ ${
                          schedule.end
                        }\nPrice: ${getSchedulePrice(
                          idx
                        )} USD\n====================\n`,
                      },
                    })
                  }
                >
                  {language === "ko"
                    ? "문의하기"
                    : language === "ja"
                    ? "お問い合わせ"
                    : "Book This Tour"}
                </ScheduleButton>
              </ScheduleCard>
            ))}
          </ScheduleSection>
        )}
      </ScheduleContainer>

      {showBookingModal && (
        <ModalOverlay onClick={() => setShowBookingModal(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={() => setShowBookingModal(false)}>
              &times;
            </CloseButton>
            <ModalItemContainer>
              <ModalItem
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/momento_korea/",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              >
                <img
                  src={InstagramIco}
                  alt="Instagram"
                  style={{ width: "24px" }}
                />
                <ModalItemText>INSTAGRAM</ModalItemText>
              </ModalItem>
              <ModalItem
                onClick={() =>
                  window.open(
                    "https://wa.me/your_whatsapp_number",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              >
                <img
                  src={WhatsappIco}
                  alt="WhatsApp"
                  style={{ width: "24px" }}
                />
                <ModalItemText>WHATS APP</ModalItemText>
              </ModalItem>
              <ModalItem
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  navigate("/contact", {
                    state: {
                      prefillMessage: `${info.title}\n${info.subtitle}\n====================\n`,
                    },
                  });
                  setShowBookingModal(false);
                }}
              >
                <img src={ChatIco} alt="Chat" style={{ width: "24px" }} />
                <ModalItemText>GET A QUOTE</ModalItemText>
              </ModalItem>
            </ModalItemContainer>
            <FooterText>
              {"Still have questions? → "}
              <ClickableText
                onClick={() => {
                  navigate("/faq");
                  setShowBookingModal(false);
                }}
              >
                CHECK OUT FAQ
              </ClickableText>
            </FooterText>
          </ModalContent>
        </ModalOverlay>
      )}
    </DetailsContainer>
  );
};

export default TourDetailsPage;

/* Styled Components */

const DetailsContainer = styled.div`
  padding-top: 80px;
  background: #1a1a1a;
  color: #fff;
  min-height: 220vh;
`;

const TourDetailsContainer = styled.div`
  max-width: 900px;
  margin: 0 auto 3rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 3rem;
  border: 1px solid rgba(255, 215, 0, 0.2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  position: relative;
`;

const BackButton = styled.button`
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 1.5rem;
  background: transparent;
  color: #ffd700;
  border: none;
  cursor: pointer;
  &:hover {
    color: #ffa500;
  }
`;

const Section = styled.div`
  margin: 2rem 0;
`;

const SectionTitle = styled.h3`
  font-size: 1.5rem;
  color: #ffd700;
  margin-bottom: 1rem;
  text-align: center;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin: 0.5rem 0;
  padding-left: 1rem;
  position: relative;
  &::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #ffd700;
  }
`;

const Description = styled.p`
  color: #ccc;
  font-size: 1rem;
  line-height: 1.6;
  margin: 2rem 0;
  text-align: center;
  font-style: italic;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const ActionButton = styled.button`
  display: block;
  width: 100%;
  max-width: 180px;
  margin: 0 auto;
  padding: 0.8rem 1.5rem;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  border: none;
  border-radius: 50px;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
  }
  @media (min-width: 769px) {
    max-width: 220px;
    font-size: 1.1rem;
  }
`;

/* Schedule Section styled-components */

const ScheduleContainer = styled.div`
  max-width: 900px;
  margin: 0 auto 3rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 3rem;
  border: 1px solid rgba(255, 215, 0, 0.2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  position: relative;
`;

const ScheduleSection = styled.div`
  max-width: 900px;
  margin: 1rem auto;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin-top: -15px;
`;

const ScheduleFirstTitle = styled.h3`
  font-size: 2rem;
  color: #ffd700;
  /* margin-bottom: 0.3rem; */
`;

const ScheduleCard = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 10px;
  width: 95%;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ScheduleText = styled.p`
  font-size: 0.9rem;
  color: #ccc;
  margin-bottom: 0.5rem;
`;

const ScheduleTitle = styled.h3`
  font-size: 1.1rem;
  color: #ffd700;
`;

const SchedulePrice = styled.p`
  font-size: 1rem;
  color: #fff;
  margin-bottom: 0.5rem;
`;

const ScheduleButton = styled.button`
  padding: 0.5rem 1rem;
  background: #ffa730;
  border: none;
  border-radius: 5px;
  color: #000;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: #ff9610;
    transform: translateY(-2px);
  }
`;

/* Modal styled-components */
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const ModalContent = styled.div`
  background: #fff;
  color: #000;
  border-radius: 10px;
  padding: 2rem;
  position: relative;
  width: 90%;
  max-width: 300px;
  text-align: center;
`;

const ModalItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-top: 10px;
`;

const ModalItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const ModalItemText = styled.span`
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

const FooterText = styled.p`
  font-size: 0.8rem;
  margin-top: 1rem;
`;

const ClickableText = styled.span`
  color: #007bff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
